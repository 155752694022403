import { atom, useAtom } from "jotai";
import { useModal } from "mui-modal-provider";
import { ActiveQuest, GenericQuestStepConfig, QuestGroup, QuestsProgress } from "../../components/quests/quests.types";
import {
  getNextQuestStep,
  getPreviousQuestStep,
  getQuestConfigFromActiveQuest,
  getQuestsStepConfig,
} from "../../components/quests/quests.util";
import { QuestVideoPlayerModal } from "../../components/quests/QuestVideoPlayerModal";
import { reclaim } from "../../reclaim-api";
import { useCallbackSafeRef } from "../useCallbackSafeRef";
import { useNotifications } from "../useNotifications";
import { useOurRouter } from "../useOurRouter";

const activeQuestAtom = atom<ActiveQuest<QuestGroup> | undefined>(undefined);
const questsAtom = atom<QuestsProgress | undefined>(undefined);
const loadingQuestsAtom = atom<boolean>(false);
const popperBackgroundedAtom = atom<boolean>(false);
const popperActiveConfigAtom = atom<ActiveQuest<QuestGroup> | undefined>(undefined);

const loadingErrorAtom = atom<Error | undefined>(undefined);

export type UseQuestsStateReturnType = {
  activeQuest?: ActiveQuest<QuestGroup>;
  quests?: QuestsProgress;
  questsLoading: boolean;
  loadingError?: Error;
};

export const useQuestsState = (): UseQuestsStateReturnType => {
  const [quests] = useAtom(questsAtom);
  const [activeQuest] = useAtom(activeQuestAtom);
  const [questsLoading] = useAtom(loadingQuestsAtom);
  const [loadingError] = useAtom(loadingErrorAtom);

  return { activeQuest, quests, questsLoading, loadingError };
};

export type UseQuestsActionsReturnType = {
  loadQuests: () => Promise<QuestsProgress | undefined>;
  updateQuests: (quests: QuestsProgress) => void;
  openActiveQuestPopper: () => void;
  setActiveQuest: (quest: ActiveQuest<QuestGroup> | undefined) => void;
  completeQuest: (quest: ActiveQuest<QuestGroup>) => void;
  onStepComplete: () => GenericQuestStepConfig | undefined;
  onBack: () => GenericQuestStepConfig | undefined;
  setPopperActive: (active: boolean) => void;
  setPopperBackgrounded: (backgrounded: boolean) => void;
};

export const useQuestsActions = (config?: ActiveQuest<QuestGroup>): UseQuestsActionsReturnType => {
  const [activeQuest, _setActiveQuest] = useAtom(activeQuestAtom);
  const [quests, setQuests] = useAtom(questsAtom);
  const [, setPopperActiveConfig] = useAtom(popperActiveConfigAtom);
  const [, setPopperBackgrounded] = useAtom(popperBackgroundedAtom);
  const [, setQuestsLoading] = useAtom(loadingQuestsAtom);
  const [, setLoadingError] = useAtom(loadingErrorAtom);

  const router = useOurRouter();

  const { sendNotification } = useNotifications();

  const { showModal } = useModal({ disableAutoDestroy: true });

  const loadQuests = useCallbackSafeRef(async () => {
    setQuestsLoading(true);

    try {
      const quests = await reclaim.users.getCompletedQuests();
      setQuests(quests);
      void setLoadingError(undefined);
      return quests;
    } catch (cause) {
      const message = "Failed to get setup guide progress.";
      void setLoadingError(new Error(message, { cause }));
      sendNotification({
        message: `${message} Please contact support if this problem persists.`,
        type: "error",
      });
    } finally {
      setQuestsLoading(false);
    }
  });

  const updateQuests = useCallbackSafeRef((quests: QuestsProgress) => {
    setQuests(quests);
  });

  const completeQuest = useCallbackSafeRef(async (activeQuest: ActiveQuest<QuestGroup>) => {
    _setActiveQuest(undefined);
    const quest = getQuestConfigFromActiveQuest(activeQuest);
    const questComplete = !!quests?.[activeQuest.group]?.quests?.[activeQuest.quest]?.complete;

    if (!quest?.indeterminant && !questComplete) {
      try {
        const newQuests = await reclaim.users.completeQuest(activeQuest.quest);
        setQuests(newQuests);
      } catch (cause) {
        sendNotification({
          message: "Failed to save setup guide progress. Please contact support if this problem persists.",
          type: "error",
        });
      }
    }
  });

  const setNextStepAsActive = useCallbackSafeRef((quest: ActiveQuest<QuestGroup>) => {
    const next = getNextQuestStep(quest);
    !!next ? _setActiveQuest({ ...quest, step: next.id }) : void completeQuest(quest);
  });

  const setPreviousStepAsActive = useCallbackSafeRef((quest: ActiveQuest<QuestGroup>) => {
    const previous = getPreviousQuestStep(quest);
    if (previous) {
      _setActiveQuest({ ...quest, step: previous.id });
    }
  });

  const setActiveQuest = useCallbackSafeRef((quest: ActiveQuest<QuestGroup> | undefined, goingBack = false) => {
    if (quest) {
      const step = getQuestsStepConfig(quest);
      if (!step) {
        _setActiveQuest(undefined);
        throw new Error("Must provide a quest step");
      } else {
        switch (step.type) {
          case "url": {
            if (!step.url) {
              throw new Error("Must provide a url for url quest step");
            }
            window.open(step.url, "reclaim_slackintegration");
            setNextStepAsActive(quest);
            break;
          }
          case "redirect": {
            if (!step.redirect) {
              throw new Error("Must provide a redirect path for redirect quest step");
            }
            if (goingBack) {
              void router.push(step.redirectBack);
              setPreviousStepAsActive(quest);
              break;
            } else {
              void router.push(step.redirect);
              setNextStepAsActive(quest);
              break;
            }
          }
          case "video": {
            _setActiveQuest(quest);

            if (!step.videoLink) {
              throw new Error("Must provide a video embed link to play video");
            }

            showModal(QuestVideoPlayerModal, { step });
            break;
          }
          case "action":
          case "confetti":
          case "orb": {
            _setActiveQuest(quest);
          }
        }
      }
    } else {
      _setActiveQuest(undefined);
      setPopperBackgrounded(false);
    }
  });

  const onStepComplete = useCallbackSafeRef(() => {
    if (!activeQuest) return;

    const next = getNextQuestStep(activeQuest);
    if (next) {
      setActiveQuest({ ...activeQuest, step: next.id });
    } else {
      void completeQuest(activeQuest);
    }
    return next;
  });

  const onBack = useCallbackSafeRef(() => {
    if (!activeQuest) return;

    const prev = getPreviousQuestStep(activeQuest);
    if (prev) {
      setActiveQuest({ ...activeQuest, step: prev.id }, true);
    }
    return prev;
  });

  const setPopperActive = useCallbackSafeRef((active: boolean) => {
    if (active) {
      setPopperActiveConfig(config);
    } else {
      setPopperActiveConfig(undefined);
    }
  });

  const openActiveQuestPopper = useCallbackSafeRef(() => {
    setImmediate(() => {
      setPopperBackgrounded(false);
      setPopperActiveConfig(activeQuest);
    });
  });

  return {
    setActiveQuest,
    completeQuest,
    onStepComplete,
    loadQuests,
    updateQuests,
    onBack,
    openActiveQuestPopper,
    setPopperActive,
    setPopperBackgrounded,
  };
};

export const useQuestPopperState = (config?: ActiveQuest<QuestGroup>) => {
  const [popperActiveConfig] = useAtom(popperActiveConfigAtom);
  const [popperBackgrounded] = useAtom(popperBackgroundedAtom);

  const popperActive =
    popperActiveConfig?.group === config?.group &&
    popperActiveConfig?.quest == config?.quest &&
    popperActiveConfig?.step === config?.step;

  return {
    anyPopperActive: !!popperActiveConfig,
    popperActive,
    popperBackgrounded,
  };
};
