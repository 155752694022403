import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { useState, VFC } from "react";
import YouTube from "react-youtube";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { Override } from "../types";
import { YouTubeOptions } from "../types/youtube";
import { Loading } from "./Loading";
import { Modal, ModalProps } from "./modal/Modal";
import { DEFAULT_VIDEO_PLAYER_OPTIONS } from "./quests/quests.consts";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: 2000,
    },
    loader: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: "auto",
    },
    container: {
      position: "relative",
      width: "100%",
      height: 0,
      paddingBottom: "56.25%",
      overflow: "hidden",
    },
    iframe: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
  }),
  {
    classNamePrefix: "YoutubeVideoPlayerModal",
  }
);

export type YoutubeVideoPlayerModalJSSClassKey = keyof ReturnType<typeof useStyles>;

export type YoutubeVideoPlayerModalProps = Override<
  ModalProps,
  {
    classes?: Partial<ClassNameMap<YoutubeVideoPlayerModalJSSClassKey>>;
    className?: string;
    videoId?: string;
    videoOptions?: YouTubeOptions;
    onClose?: () => void;
  }
>;

export const YoutubeVideoPlayerModal: VFC<YoutubeVideoPlayerModalProps> = ({
  className,
  classes: extClasses,
  videoId,
  videoOptions,
  onClose,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const [ready, setReady] = useState(false);

  const opts = videoOptions || DEFAULT_VIDEO_PLAYER_OPTIONS;

  const onReady = useCallbackSafeRef(() => {
    setReady(true);
  });

  return (
    <Modal className={clsx(classes.root, className)} {...rest} onClose={onClose} fullWidth maxWidth="md">
      <YouTube
        className={classes.container}
        iframeClassName={classes.iframe}
        videoId={videoId}
        onReady={onReady}
        onEnd={onClose}
        opts={{ playerVars: opts }}
      />
      {!ready && <Loading className={classes.loader} />}
    </Modal>
  );
};
